<template>
  <b-container class="mt-2">
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <b-card-text class="text-center mt-2">
                <p>Tienes cuenta?</p>
                <b-link :to="{ name: 'auth-login' }">
                  <h4><span class="badge badge-success">&nbsp;Iniciar sesion</span></h4>
                </b-link>
              </b-card-text>
              <b-card-text class="text-center mt-2">
                <p>Registrate</p>
                <b-link :to="{ name: 'auth-register-client' }">
                  <h4><span class="badge badge-success">&nbsp;Registrate como cliente</span></h4>
                </b-link>
              </b-card-text>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
  
  <script>
  import { mapActions } from 'vuex'
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import '@/@core/scss/vue/libs/vue-wizard.scss'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    BSpinner,
    BCard
  } from 'bootstrap-vue'
  
export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BOverlay,
    BSpinner,
  },
  beforeMount() {
    const referralToken = (this.$route.query.token).replace(/ /g, '+')
    
    if(!referralToken){
      this.$router.push({ name: 'home' })
    }

    const userData = JSON.parse(localStorage.getItem('userData')) || null
  
    if(userData){
      this.attachReferral({ encrypted_message: referralToken }).then(res => {
        this.$router.push({ name: 'e-commerce', params: { store_id: res.store_id } });
      }).catch(error => {
        this.$router.push({ name: 'home'})
      })
    }else{
      localStorage.setItem('referralToken', referralToken)
    }
  },
  methods: {
    ...mapActions('users', [
      'attachReferral',
    ]),
  },
}
</script>

<style lang="scss">
</style>
  